<template>
  <v-container>
    <v-card>
      <v-card-text>
        <div style="position: relative; padding-top: 56.25%;">
          <iframe
            src="https://iframe.videodelivery.net/fcd5fb7d39d4609c88837f0d3eac17d0"
            style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        </div>
        <hr class="my-4" />
        <v-row class="my-4">
          <v-btn color="primary" class="mx-auto" @click="openCal" x-large>
            Book a 15 minute discussion
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  methods: {
    openCal: function() {
      // eslint-disable-next-line
      Calendly.initPopupWidget({
        url: "https://calendly.com/kevin-1176/15min",
      });
      return false;
    },
  },
};
</script>
